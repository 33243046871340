import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpRequest,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import {
  AutoRefreshTokenService,
  CUSTOM_BEARER_TOKEN_INTERCEPTOR_CONFIG,
  customBearerTokenInterceptor,
  provideKeycloak,
  UserActivityService,
  withAutoRefreshToken
} from 'keycloak-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '~ngx-shared/environment';
import { ClaimInterceptor, GlobalInterceptor, RoleInterceptor } from './interceptors';
import { AuthenticationConfig } from './models';
import { AuthenticationService, AuthorizationService } from './services';

export const AUTH_CONFIG_TOKEN = new InjectionToken<AuthenticationConfig>('AUTH_CONFIG_TOKEN');

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxPermissionsModule.forRoot()],
  exports: []
})
export class AuthenticationModule {
  static forRoot(config: AuthenticationConfig): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        provideKeycloak({
          config: {
            url: environment.issuer,
            realm: 'madrasa',
            clientId: config.clientId
          },
          initOptions: {
            onLoad: 'login-required',
            pkceMethod: 'S256',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
          },
          features: [
            withAutoRefreshToken({
              onInactivityTimeout: 'logout',
              sessionTimeout: 600000
            })
          ]
        }),
        { provide: AUTH_CONFIG_TOKEN, useValue: config },
        { provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RoleInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ClaimInterceptor, multi: true },
        AutoRefreshTokenService,
        UserActivityService,
        AuthenticationService,
        AuthorizationService,
        provideHttpClient(withInterceptors([customBearerTokenInterceptor])),
        {
          provide: CUSTOM_BEARER_TOKEN_INTERCEPTOR_CONFIG,
          useValue: [
            {
              shouldAddToken: async (req: HttpRequest<unknown>) => {
                return req.url.includes('graphql') || req.url.includes('api');
              }
            }
          ]
        }
      ]
    };
  }
}
