import { Routes } from '@angular/router';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { crudGuard } from '~ngx-shared/guards/crud.guard';
import { crudResolver } from '~ngx-shared/resolvers/crud.resolver';
import { detailGuard } from '~ngx-shared/guards/detail.guard';
import { breadcrumbResolver } from '~ngx-shared/resolvers/breadcrumb.resolver';
import {
  AcademyCertificatePermission,
  AcademyClassBookEntryPermission,
  AcademyCourseNamePermission,
  AcademyCoursePermission,
  AcademyCourseTypePermission,
  AcademyOrganisationPermission,
  AcademyRoomPermission,
  AcademySchoolPeriodPermission,
  AcademySchoolPermission,
  AcademySubjectPermission,
  AcademyTeacherHourlyRatePermission,
  AcademyUnitPermission,
  AccountingCashBookEntryCategoryPermission,
  AccountingCashBookEntryPermission,
  AccountingMembershipFeePermission,
  AccountingStudentBalancePermission,
  AccountingTeacherWagePermission,
  CoreTenantPermission,
  FilePersonDocumentTypePermission,
  StaffDirectorPermission,
  StaffEducationTypePermission,
  StaffFunctionPermission,
  StaffManagerPermission,
  StaffMemberPermission,
  StaffOrganisatorPermission,
  StaffParentPermission,
  StaffPriceCategoryPermission,
  StaffStatusPermission,
  StaffStudentPermission,
  StaffTeacherPermission
} from './models';
import { Role } from './core/enums';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        redirectTo: '/academy',
        pathMatch: 'full'
      },
      {
        path: 'core',
        canActivate: [ngxPermissionsGuard],
        data: {
          breadcrumb: 'system',
          permissions: {
            only: CoreTenantPermission.READ,
            redirectTo: '/'
          }
        },
        children: [
          {
            path: 'tenants',
            data: {
              breadcrumb: 'tenants',
              icon: 'pi pi-fw pi-building-columns'
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./core/pages/tenant-list-page/tenant-list-page.component').then(
                    m => m.TenantListPageComponent
                  )
              },
              {
                path: 'detail/:tenant-id',
                loadComponent: () =>
                  import('./core/pages/tenant-detail-page/tenant-detail-page.component').then(
                    m => m.TenantDetailPageComponent
                  ),
                canActivate: [detailGuard('tenant-id')],
                resolve: { crud: crudResolver('tenant-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'tenant'
                }
              },
              {
                path: ':tenant-id',
                loadComponent: () =>
                  import(
                    './core/pages/create-update-tenant-form-page/create-update-tenant-form-page.component'
                  ).then(m => m.CreateUpdateTenantFormPageComponent),
                canActivate: [crudGuard('tenant-id')],
                resolve: {
                  crud: crudResolver('tenant-id'),
                  breadcrumb: breadcrumbResolver('tenant-id')
                },
                data: {
                  breadcrumbParamValue: 'tenant'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'accounting',
        data: {
          breadcrumb: 'accounting'
        },
        children: [
          {
            path: '',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'settlements',
            data: {
              breadcrumb: 'settlements',
              icon: 'pi pi-fw pi-cog'
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./accounting/pages/settlement-page/settlement-page.component').then(
                    m => m.SettlementPageComponent
                  ),
                canActivate: [ngxPermissionsGuard],
                data: {
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                }
              },
              {
                path: 'travel-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'travel_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/',
                    pathMatch: 'full'
                  },
                  {
                    path: ':travel-cost-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/create-update-travel-cost-form-page/create-update-travel-cost-form-page.component'
                      ).then(m => m.CreateUpdateTravelCostFormPageComponent),
                    canActivate: [crudGuard('travel-cost-id')],
                    resolve: {
                      crud: crudResolver('travel-cost-id'),
                      breadcrumb: breadcrumbResolver('travel-cost-id')
                    },
                    data: {
                      breadcrumbParamValue: 'travel_cost'
                    }
                  }
                ]
              },
              {
                path: 'operating-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'operating_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/',
                    pathMatch: 'full'
                  },
                  {
                    path: ':operating-cost-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/create-update-operating-cost-form-page/create-update-operating-cost-form-page.component'
                      ).then(m => m.CreateUpdateOperatingCostFormPageComponent),
                    canActivate: [crudGuard('operating-cost-id')],
                    resolve: {
                      crud: crudResolver('operating-cost-id'),
                      breadcrumb: breadcrumbResolver('operating-cost-id')
                    },
                    data: {
                      breadcrumbParamValue: 'operating_cost'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'account-plan',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'account_plan',
              icon: 'pi pi-fw pi-money-bill',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './accounting/pages/account-plan-overview-page/account-plan-overview-page.component'
                  ).then(m => m.AccountPlanOverviewPageComponent)
              },
              {
                path: 'edit',
                loadComponent: () =>
                  import('./accounting/pages/account-plan-page/account-plan-page.component').then(
                    m => m.AccountPlanPageComponent
                  ),
                data: {
                  breadcrumb: 'edit_value',
                  breadcrumbParamValue: 'account_plan',
                  icon: 'pi pi-fw pi-money-bill'
                }
              },
              {
                path: 'account-groups',
                data: {
                  breadcrumb: 'account_groups',
                  icon: 'pi pi-fw pi-money-bill'
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/accounting/account-plan',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:account-group-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/account-group-detail-page/account-group-detail-page.component'
                      ).then(m => m.AccountGroupDetailPageComponent),
                    canActivate: [detailGuard('account-group-id')],
                    resolve: { crud: crudResolver('account-group-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'account_group'
                    }
                  },
                  {
                    path: ':account-group-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/create-update-account-group-form-page/create-update-account-group-form-page.component'
                      ).then(m => m.CreateUpdateAccountGroupFormPageComponent),
                    canActivate: [crudGuard('account-group-id')],
                    resolve: {
                      crud: crudResolver('account-group-id'),
                      breadcrumb: breadcrumbResolver('account-group-id')
                    },
                    data: {
                      breadcrumbParamValue: 'account_group'
                    }
                  }
                ]
              },
              {
                path: 'accounts',
                data: {
                  breadcrumb: 'account',
                  icon: 'pi pi-fw pi-money-bill'
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/accounting/account-plan',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:account-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/account-detail-page/account-detail-page.component'
                      ).then(m => m.AccountDetailPageComponent),
                    canActivate: [detailGuard('account-id')],
                    resolve: { crud: crudResolver('account-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'account'
                    }
                  },
                  {
                    path: ':account-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/create-update-account-form-page/create-update-account-form-page.component'
                      ).then(m => m.CreateUpdateAccountFormPageComponent),
                    canActivate: [crudGuard('account-id')],
                    resolve: {
                      crud: crudResolver('account-id'),
                      breadcrumb: breadcrumbResolver('account-id')
                    },
                    data: {
                      breadcrumbParamValue: 'account'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'earnings-report',
            data: {
              breadcrumb: 'earnings_report',
              icon: 'pi pi-fw pi-chart-scatter'
            },
            loadComponent: () =>
              import('./accounting/pages/earnings-report-page/earnings-report-page.component').then(
                m => m.EarningsReportPageComponent
              )
          },
          {
            path: 'cash-book-entries',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'cash_book_entries',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AccountingCashBookEntryPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './accounting/pages/cash-book-entry-list-page/cash-book-entry-list-page.component'
                  ).then(m => m.CashBookEntryListPageComponent)
              },
              {
                path: 'categories',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'cash_book_entry_categories',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: AccountingCashBookEntryCategoryPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './accounting/pages/cash-book-entry-category-list-page/cash-book-entry-category-list-page.component'
                      ).then(m => m.CashBookEntryCategoryListPageComponent)
                  },
                  {
                    path: ':cash-book-entry-category-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/create-update-cash-book-entry-category-form-page/create-update-cash-book-entry-category-form-page.component'
                      ).then(m => m.CreateUpdateCashBookEntryCategoryFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('cash-book-entry-category-id')],
                    resolve: {
                      crud: crudResolver('cash-book-entry-category-id'),
                      breadcrumb: breadcrumbResolver('cash-book-entry-category-id')
                    },
                    data: {
                      breadcrumbParamValue: 'cash_book_entry_category',
                      permissions: {
                        only: [
                          ...AccountingCashBookEntryCategoryPermission.CREATE,
                          ...AccountingCashBookEntryCategoryPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'detail/:cash-book-entry-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/cash-book-entry-detail-page/cash-book-entry-detail-page.component'
                  ).then(m => m.CashBookEntryDetailPageComponent),
                canActivate: [ngxPermissionsGuard, detailGuard('cash-book-entry-id')],
                resolve: { crud: crudResolver('cash-book-entry-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'cash_book_entry',
                  permissions: {
                    only: AccountingCashBookEntryPermission.READ,
                    redirectTo: '/'
                  }
                }
              },
              {
                path: ':cash-book-entry-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/create-update-cash-book-entry-form-page/create-update-cash-book-entry-form-page.component'
                  ).then(m => m.CreateUpdateCashBookEntryFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('cash-book-entry-id')],
                resolve: {
                  crud: crudResolver('cash-book-entry-id'),
                  breadcrumb: breadcrumbResolver('cash-book-entry-id')
                },
                data: {
                  breadcrumbParamValue: 'cash_book_entry',
                  permissions: {
                    only: [
                      ...AccountingCashBookEntryPermission.CREATE,
                      ...AccountingCashBookEntryPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'entries',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'account_entries',
              icon: 'pi pi-fw pi-money-bill',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./accounting/pages/entry-list-page/entry-list-page.component').then(
                    m => m.EntryListPageComponent
                  )
              },
              {
                path: 'detail/:entry-id',
                loadComponent: () =>
                  import('./accounting/pages/entry-detail-page/entry-detail-page.component').then(
                    m => m.EntryDetailPageComponent
                  ),
                canActivate: [detailGuard('entry-id')],
                resolve: { crud: crudResolver('entry-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'account_entry'
                }
              },
              {
                path: ':entry-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/create-entry-form-page/create-entry-form-page.component'
                  ).then(m => m.CreateEntryFormPageComponent),
                canActivate: [crudGuard('entry-id', true, false)],
                resolve: {
                  crud: crudResolver('entry-id'),
                  breadcrumb: breadcrumbResolver('entry-id')
                },
                data: {
                  breadcrumbParamValue: 'account_entry'
                }
              }
            ]
          },
          {
            path: 'student-balances',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'student_balances',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AccountingStudentBalancePermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './accounting/pages/student-balance-list-page/student-balance-list-page.component'
                  ).then(m => m.StudentBalanceListPageComponent)
              },
              {
                path: 'detail/:student-balance-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/student-balance-detail-page/student-balance-detail-page.component'
                  ).then(m => m.StudentBalanceDetailPageComponent),
                canActivate: [detailGuard('student-balance-id')],
                resolve: { crud: crudResolver('student-balance-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'student_balance'
                }
              },
              {
                path: ':student-balance-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/create-update-student-balance-form-page/create-update-student-balance-form-page.component'
                  ).then(m => m.CreateUpdateStudentBalanceFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('student-balance-id')],
                resolve: {
                  crud: crudResolver('student-balance-id'),
                  breadcrumb: breadcrumbResolver('student-balance-id')
                },
                data: {
                  breadcrumbParamValue: 'student_balance',
                  permissions: {
                    only: [
                      ...AccountingStudentBalancePermission.CREATE,
                      ...AccountingStudentBalancePermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'membership-fees',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'membership_fees',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AccountingMembershipFeePermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './accounting/pages/membership-fee-list-page/membership-fee-list-page.component'
                  ).then(m => m.MembershipFeeListPageComponent)
              },
              {
                path: 'detail/:membership-fee-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/membership-fee-detail-page/membership-fee-detail-page.component'
                  ).then(m => m.MembershipFeeDetailPageComponent),
                canActivate: [detailGuard('membership-fee-id')],
                resolve: { crud: crudResolver('membership-fee-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'membership_fee'
                }
              },
              {
                path: ':membership-fee-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/create-update-membership-fee-form-page/create-update-membership-fee-form-page.component'
                  ).then(m => m.CreateUpdateMembershipFeeFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('membership-fee-id')],
                resolve: {
                  crud: crudResolver('membership-fee-id'),
                  breadcrumb: breadcrumbResolver('membership-fee-id')
                },
                data: {
                  breadcrumbParamValue: 'membership_fee',
                  permissions: {
                    only: [
                      ...AccountingMembershipFeePermission.CREATE,
                      ...AccountingMembershipFeePermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'teacher-wages',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'teacher_wages',
              icon: 'pi pi-fw pi-money-bill',
              permissions: {
                only: AccountingTeacherWagePermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './accounting/pages/teacher-wage-list-page/teacher-wage-list-page.component'
                  ).then(m => m.TeacherWageListPageComponent)
              },
              {
                path: 'detail/:teacher-wage-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/teacher-wage-detail-page/teacher-wage-detail-page.component'
                  ).then(m => m.TeacherWageDetailPageComponent),
                canActivate: [detailGuard('teacher-wage-id')],
                resolve: { crud: crudResolver('teacher-wage-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'teacher_wage'
                }
              },
              {
                path: ':teacher-wage-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/create-update-teacher-wage-form-page/create-update-teacher-wage-form-page.component'
                  ).then(m => m.CreateUpdateTeacherWageFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('teacher-wage-id')],
                resolve: {
                  crud: crudResolver('teacher-wage-id'),
                  breadcrumb: breadcrumbResolver('teacher-wage-id')
                },
                data: {
                  breadcrumbParamValue: 'teacher_wage',
                  permissions: {
                    only: [
                      ...AccountingTeacherWagePermission.CREATE,
                      ...AccountingTeacherWagePermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'bank-data-import',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'bank_data_import',
              icon: 'pi pi-fw pi-file-import',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './accounting/pages/bank-data-import-list-page/bank-data-import-list-page.component'
                  ).then(m => m.BankDataImportListPageComponent)
              },
              {
                path: 'new',
                loadComponent: () =>
                  import(
                    './accounting/pages/create-bank-data-import-form-page/create-bank-data-import-form-page.component'
                  ).then(m => m.CreateBankDataImportFormPageComponent),
                data: {
                  breadcrumb: 'add_value',
                  breadcrumbParamValue: 'bank_data_import'
                }
              },
              {
                path: ':bank-data-id',
                loadComponent: () =>
                  import(
                    './accounting/pages/bank-data-import-page/bank-data-import-page.component'
                  ).then(m => m.BankDataImportPageComponent),
                data: {
                  breadcrumb: 'edit_value',
                  breadcrumbParamValue: 'bank_data_import'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'academy',
        data: {
          breadcrumb: 'academy'
        },
        children: [
          {
            path: '',
            redirectTo: '/academy/dashboard',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            loadComponent: () =>
              import('./academy/pages/dashboard-page/dashboard-page.component').then(
                m => m.DashboardPageComponent
              ),
            data: {
              breadcrumb: 'dashboard',
              icon: 'pi pi-fw pi-home'
            }
          },
          {
            path: 'class-book-entries',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'class_book_entries',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyClassBookEntryPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './academy/pages/class-book-entry-list-page/class-book-entry-list-page.component'
                  ).then(m => m.ClassBookEntryListPageComponent)
              },
              {
                path: 'detail/:class-book-entry-id',
                loadComponent: () =>
                  import(
                    './academy/pages/class-book-entry-detail-page/class-book-entry-detail-page.component'
                  ).then(m => m.ClassBookEntryDetailPageComponent),
                canActivate: [detailGuard('class-book-entry-id')],
                resolve: { crud: crudResolver('class-book-entry-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'class_book_entry'
                }
              },
              {
                path: ':class-book-entry-id',
                loadComponent: () =>
                  import(
                    './academy/pages/update-class-book-entry-form-page/update-class-book-entry-form-page.component'
                  ).then(m => m.UpdateClassBookEntryFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('class-book-entry-id', false)],
                resolve: {
                  crud: crudResolver('class-book-entry-id'),
                  breadcrumb: breadcrumbResolver('class-book-entry-id')
                },
                data: {
                  breadcrumbParamValue: 'class_book_entry',
                  permissions: {
                    only: [
                      ...AcademyClassBookEntryPermission.CREATE,
                      ...AcademyClassBookEntryPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'courses',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'courses',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyCoursePermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./academy/pages/course-list-page/course-list-page.component').then(
                    m => m.CourseListPageComponent
                  )
              },
              {
                path: 'evaluations',
                loadComponent: () =>
                  import(
                    './academy/pages/course-evaluations-page/course-evaluations-page.component'
                  ).then(m => m.CourseEvaluationsPageComponent),
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'evaluations',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCoursePermission.CREATE,
                    redirectTo: '/'
                  }
                }
              },
              {
                path: 'certificates',
                data: {
                  breadcrumb: 'certificates',
                  icon: 'pi pi-fw pi-graduation-cap'
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/courses',
                    pathMatch: 'full'
                  },
                  {
                    path: ':certificate-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-certificate-form-page/create-update-certificate-form-page.component'
                      ).then(m => m.CreateUpdateCertificateFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('certificate-id')],
                    resolve: {
                      crud: crudResolver('certificate-id'),
                      breadcrumb: breadcrumbResolver('certificate-id')
                    },
                    data: {
                      breadcrumbParamValue: 'certificate',
                      permissions: {
                        only: AcademyCertificatePermission.UPDATE,
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'detail/:course-id',
                loadComponent: () =>
                  import('./academy/pages/course-detail-page/course-detail-page.component').then(
                    m => m.CourseDetailPageComponent
                  ),
                canActivate: [detailGuard('course-id')],
                resolve: { crud: crudResolver('course-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'course'
                }
              },
              {
                path: ':course-id',
                canActivate: [crudGuard('course-id')],
                resolve: {
                  crud: crudResolver('course-id'),
                  breadcrumb: breadcrumbResolver('course-id')
                },
                data: {
                  breadcrumbParamValue: 'course'
                },
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-course-form-page/create-update-course-form-page.component'
                      ).then(m => m.CreateUpdateCourseFormPageComponent),
                    canActivate: [ngxPermissionsGuard],
                    data: {
                      permissions: {
                        only: [
                          ...AcademyCoursePermission.CREATE,
                          ...AcademyCoursePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  },
                  {
                    path: 'certificates',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-course-certificate-form-page/create-update-course-certificate-form-page.component'
                      ).then(m => m.CreateUpdateCourseCertificateFormPageComponent),
                    canActivate: [ngxPermissionsGuard],
                    data: {
                      breadcrumb: 'certificates',
                      icon: 'pi pi-fw pi-graduation-cap',
                      permissions: {
                        only: AcademyCertificatePermission.UPDATE,
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'rooms',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'rooms',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyRoomPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./academy/pages/room-list-page/room-list-page.component').then(
                    m => m.RoomListPageComponent
                  )
              },
              {
                path: 'detail/:room-id',
                loadComponent: () =>
                  import('./academy/pages/room-detail-page/room-detail-page.component').then(
                    m => m.RoomDetailPageComponent
                  ),
                canActivate: [detailGuard('room-id')],
                resolve: { crud: crudResolver('room-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'room'
                }
              },
              {
                path: ':room-id',
                loadComponent: () =>
                  import(
                    './academy/pages/create-update-room-form-page/create-update-room-form-page.component'
                  ).then(m => m.CreateUpdateRoomFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('room-id')],
                resolve: {
                  crud: crudResolver('room-id'),
                  breadcrumb: breadcrumbResolver('room-id')
                },
                data: {
                  breadcrumbParamValue: 'room',
                  permissions: {
                    only: [...AcademyRoomPermission.CREATE, ...AcademyRoomPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'schools',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'schools',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademySchoolPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./academy/pages/school-list-page/school-list-page.component').then(
                    m => m.SchoolListPageComponent
                  )
              },
              {
                path: 'detail/:school-id',
                loadComponent: () =>
                  import('./academy/pages/school-detail-page/school-detail-page.component').then(
                    m => m.SchoolDetailPageComponent
                  ),
                canActivate: [detailGuard('school-id')],
                resolve: { crud: crudResolver('school-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'school'
                }
              },
              {
                path: ':school-id',
                loadComponent: () =>
                  import(
                    './academy/pages/create-update-school-form-page/create-update-school-form-page.component'
                  ).then(m => m.CreateUpdateSchoolFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('school-id')],
                resolve: {
                  crud: crudResolver('school-id'),
                  breadcrumb: breadcrumbResolver('school-id')
                },
                data: {
                  breadcrumbParamValue: 'school',
                  permissions: {
                    only: [...AcademySchoolPermission.CREATE, ...AcademySchoolPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'units',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'units',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyUnitPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./academy/pages/unit-list-page/unit-list-page.component').then(
                    m => m.UnitListPageComponent
                  )
              },
              {
                path: 'detail/:unit-id',
                loadComponent: () =>
                  import('./academy/pages/unit-detail-page/unit-detail-page.component').then(
                    m => m.UnitDetailPageComponent
                  ),
                canActivate: [detailGuard('unit-id')],
                resolve: { crud: crudResolver('unit-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'unit'
                }
              },
              {
                path: ':unit-id',
                loadComponent: () =>
                  import(
                    './academy/pages/create-update-unit-form-page/create-update-unit-form-page.component'
                  ).then(m => m.CreateUpdateUnitFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('unit-id')],
                resolve: {
                  crud: crudResolver('unit-id'),
                  breadcrumb: breadcrumbResolver('unit-id')
                },
                data: {
                  breadcrumbParamValue: 'unit',
                  permissions: {
                    only: [...AcademyUnitPermission.CREATE, ...AcademyUnitPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'statistics',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'statistics',
              icon: 'pi pi-fw pi-chart-scatter',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./academy/pages/statistic-page/statistic-page.component').then(
                    m => m.StatisticPageComponent
                  )
              }
            ]
          },
          {
            path: 'documents',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'documents',
              icon: 'pi pi-fw pi-file',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    '~madrasa/file/pages/document-list-page/document-list-page.component'
                  ).then(m => m.DocumentListPageComponent)
              }
            ]
          }
        ]
      },
      {
        path: 'staff',
        data: {
          breadcrumb: 'staff'
        },
        children: [
          {
            path: 'settings',
            data: {
              breadcrumb: 'settings',
              icon: 'pi pi-fw pi-cog'
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/settings-page/settings-page.component').then(
                    m => m.SettingsPageComponent
                  )
              }
            ]
          },
          {
            path: 'profile',
            loadComponent: () =>
              import('./staff/pages/profile-page/profile-page.component').then(
                m => m.ProfilePageComponent
              ),
            data: {
              breadcrumb: 'profile',
              icon: 'pi pi-fw pi-user'
            }
          },
          {
            path: 'people',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'people',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                except: Role.TEACHER,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/person-list-page/person-list-page.component').then(
                    m => m.PersonListPageComponent
                  )
              },
              {
                path: 'detail/:person-id',
                loadComponent: () =>
                  import('./staff/pages/person-detail-page/person-detail-page.component').then(
                    m => m.PersonDetailPageComponent
                  ),
                canActivate: [detailGuard('person-id')],
                resolve: { crud: crudResolver('person-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'person'
                }
              },
              {
                path: ':person-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-person-data-form-page/create-person-data-form-page.component'
                  ).then(m => m.CreatePersonDataFormPageComponent),
                canActivate: [crudGuard('person-id')],
                resolve: {
                  crud: crudResolver('person-id'),
                  breadcrumb: breadcrumbResolver('person-id')
                },
                data: {
                  breadcrumbParamValue: 'person'
                }
              }
            ]
          },
          {
            path: 'new-registration',
            loadComponent: () =>
              import(
                './staff/pages/new-registration-wizard-page/new-registration-wizard-page.component'
              ).then(m => m.NewRegistrationWizardPageComponent),
            data: {
              breadcrumb: 'madrasa.components.staff.new_registration_wizard.new_registration',
              icon: 'pi pi-fw pi-building-columns'
            }
          },
          {
            path: 'students',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'students',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffStudentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/student-list-page/student-list-page.component').then(
                    m => m.StudentListPageComponent
                  )
              },
              {
                path: 'detail/:student-id',
                redirectTo: '/staff/people/detail/:student-id'
              },
              {
                path: ':student-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-student-data-form-page/create-student-data-form-page.component'
                  ).then(m => m.CreateStudentDataFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('student-id')],
                resolve: {
                  crud: crudResolver('student-id'),
                  breadcrumb: breadcrumbResolver('student-id')
                },
                data: {
                  breadcrumbParamValue: 'student',
                  permissions: {
                    only: [...StaffStudentPermission.CREATE, ...StaffStudentPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'teachers',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'teachers',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffTeacherPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/teacher-list-page/teacher-list-page.component').then(
                    m => m.TeacherListPageComponent
                  )
              },
              {
                path: 'detail/:teacher-id',
                redirectTo: '/staff/people/detail/:teacher-id'
              },
              {
                path: ':teacher-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-teacher-data-form-page/create-teacher-data-form-page.component'
                  ).then(m => m.CreateTeacherDataFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('teacher-id')],
                resolve: {
                  crud: crudResolver('teacher-id'),
                  breadcrumb: breadcrumbResolver('teacher-id')
                },
                data: {
                  breadcrumbParamValue: 'teacher',
                  permissions: {
                    only: [...StaffTeacherPermission.CREATE, ...StaffTeacherPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'directors',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'directors',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffStudentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/director-list-page/director-list-page.component').then(
                    m => m.DirectorListPageComponent
                  )
              },
              {
                path: 'detail/:director-id',
                redirectTo: '/staff/people/detail/:director-id'
              },
              {
                path: ':director-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-director-data-form-page/create-director-data-form-page.component'
                  ).then(m => m.CreateDirectorDataFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('director-id')],
                resolve: {
                  crud: crudResolver('director-id'),
                  breadcrumb: breadcrumbResolver('director-id')
                },
                data: {
                  breadcrumbParamValue: 'director',
                  permissions: {
                    only: [...StaffDirectorPermission.CREATE, ...StaffDirectorPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'parents',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'parents',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffParentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/parent-list-page/parent-list-page.component').then(
                    m => m.ParentListPageComponent
                  )
              },
              {
                path: 'detail/:parent-id',
                redirectTo: '/staff/people/detail/:parent-id'
              },
              {
                path: ':parent-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-parent-data-form-page/create-parent-data-form-page.component'
                  ).then(m => m.CreateParentDataFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('parent-id')],
                resolve: {
                  crud: crudResolver('parent-id'),
                  breadcrumb: breadcrumbResolver('parent-id')
                },
                data: {
                  breadcrumbParamValue: 'parent',
                  permissions: {
                    only: [...StaffParentPermission.CREATE, ...StaffParentPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'organisators',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'organisators',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffOrganisatorPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './staff/pages/organisator-list-page/organisator-list-page.component'
                  ).then(m => m.OrganisatorListPageComponent)
              },
              {
                path: 'detail/:organisator-id',
                redirectTo: '/staff/people/detail/:organisator-id'
              },
              {
                path: ':organisator-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-organisator-data-form-page/create-organisator-data-form-page.component'
                  ).then(m => m.CreateOrganisatorDataFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('organisator-id')],
                resolve: {
                  crud: crudResolver('organisator-id'),
                  breadcrumb: breadcrumbResolver('organisator-id')
                },
                data: {
                  breadcrumbParamValue: 'organisator',
                  permissions: {
                    only: [
                      ...StaffOrganisatorPermission.CREATE,
                      ...StaffOrganisatorPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'managers',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'managers',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffManagerPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/manager-list-page/manager-list-page.component').then(
                    m => m.ManagerListPageComponent
                  )
              },
              {
                path: 'detail/:manager-id',
                redirectTo: '/staff/people/detail/:manager-id'
              },
              {
                path: ':manager-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-manager-data-form-page/create-manager-data-form-page.component'
                  ).then(m => m.CreateManagerDataFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('manager-id')],
                resolve: {
                  crud: crudResolver('manager-id'),
                  breadcrumb: breadcrumbResolver('manager-id')
                },
                data: {
                  breadcrumbParamValue: 'manager',
                  permissions: {
                    only: [...StaffManagerPermission.CREATE, ...StaffManagerPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'members',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'members',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffMemberPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./staff/pages/member-list-page/member-list-page.component').then(
                    m => m.MemberListPageComponent
                  )
              },
              {
                path: 'detail/:member-id',
                redirectTo: '/staff/people/detail/:member-id'
              },
              {
                path: ':member-id',
                loadComponent: () =>
                  import(
                    './staff/pages/create-member-data-form-page/create-member-data-form-page.component'
                  ).then(m => m.CreateMemberDataFormPageComponent),
                canActivate: [ngxPermissionsGuard, crudGuard('member-id')],
                resolve: {
                  crud: crudResolver('member-id'),
                  breadcrumb: breadcrumbResolver('member-id')
                },
                data: {
                  breadcrumbParamValue: 'member',
                  permissions: {
                    only: [...StaffMemberPermission.CREATE, ...StaffMemberPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'administration',
        data: {
          breadcrumb: 'administration'
        },
        children: [
          {
            path: '',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'print',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'print',
              icon: 'pi pi-fw pi-cog',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './print/pages/print-management-page/print-management-page.component'
                  ).then(m => m.PrintManagementPageComponent)
              },
              {
                path: 'paper-templates',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'paper_templates',
                  icon: 'pi pi-fw pi-print',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/print',
                    pathMatch: 'full'
                  },
                  {
                    path: ':paper-template-id',
                    loadComponent: () =>
                      import(
                        './print/pages/create-update-paper-template-form-page/create-update-paper-template-form-page.component'
                      ).then(m => m.CreateUpdatePaperTemplateFormPageComponent),
                    canActivate: [crudGuard('paper-template-id')],
                    resolve: {
                      crud: crudResolver('paper-template-id'),
                      breadcrumb: breadcrumbResolver('paper-template-id')
                    },
                    data: {
                      breadcrumbParamValue: 'paper_template'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'accounting',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'accounting',
              icon: 'pi pi-fw pi-calculator',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './accounting/pages/accounting-management-page/accounting-management-page.component'
                  ).then(m => m.AccountingManagementPageComponent)
              },
              {
                path: 'travel-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'travel_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/accounting',
                    pathMatch: 'full'
                  },
                  {
                    path: 'prices',
                    data: {
                      breadcrumb: 'travel_cost_prices',
                      icon: 'pi pi-fw pi-money-bill'
                    },
                    children: [
                      {
                        path: '',
                        redirectTo: '/administration/accounting',
                        pathMatch: 'full'
                      },
                      {
                        path: ':travel-cost-price-id',
                        loadComponent: () =>
                          import(
                            './accounting/pages/create-update-travel-cost-price-form-page/create-update-travel-cost-price-form-page.component'
                          ).then(m => m.CreateUpdateTravelCostPriceFormPageComponent),
                        canActivate: [crudGuard('travel-cost-price-id')],
                        resolve: {
                          crud: crudResolver('travel-cost-price-id'),
                          breadcrumb: breadcrumbResolver('travel-cost-price-id')
                        },
                        data: {
                          breadcrumbParamValue: 'travel_cost_price'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'operating-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'operating_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/accounting',
                    pathMatch: 'full'
                  },
                  {
                    path: 'types',
                    data: {
                      breadcrumb: 'operating_cost_types',
                      icon: 'pi pi-fw pi-money-bill'
                    },
                    children: [
                      {
                        path: '',
                        redirectTo: '/administration/accounting',
                        pathMatch: 'full'
                      },
                      {
                        path: ':operating-cost-type-id',
                        loadComponent: () =>
                          import(
                            './accounting/pages/create-update-operating-cost-type-form-page/create-update-operating-cost-type-form-page.component'
                          ).then(m => m.CreateUpdateOperatingCostTypeFormPageComponent),
                        canActivate: [crudGuard('operating-cost-type-id')],
                        resolve: {
                          crud: crudResolver('operating-cost-type-id'),
                          breadcrumb: breadcrumbResolver('operating-cost-type-id')
                        },
                        data: {
                          breadcrumbParamValue: 'operating_cost_type'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'cost-centers',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'cost_centers',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/accounting',
                    pathMatch: 'full'
                  },
                  {
                    path: ':cost-center-id',
                    loadComponent: () =>
                      import(
                        './accounting/pages/create-update-cost-center-form-page/create-update-cost-center-form-page.component'
                      ).then(m => m.CreateUpdateCostCenterFormPageComponent),
                    canActivate: [crudGuard('cost-center-id')],
                    resolve: {
                      crud: crudResolver('cost-center-id'),
                      breadcrumb: breadcrumbResolver('cost-center-id')
                    },
                    data: {
                      breadcrumbParamValue: 'cost_center'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'staff',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'staff',
              icon: 'pi pi-fw pi-user',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './staff/pages/staff-management-page/staff-management-page.component'
                  ).then(m => m.StaffManagementPageComponent)
              },
              {
                path: 'statuses',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'status',
                  permissions: {
                    only: StaffStatusPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/staff',
                    pathMatch: 'full'
                  },
                  {
                    path: ':status-id',
                    loadComponent: () =>
                      import(
                        './staff/pages/create-update-status-form-page/create-update-status-form-page.component'
                      ).then(m => m.CreateUpdateStatusFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('status-id')],
                    resolve: {
                      crud: crudResolver('status-id'),
                      breadcrumb: breadcrumbResolver('status-id')
                    },
                    data: {
                      breadcrumbParamValue: 'status',
                      permissions: {
                        only: [...StaffStatusPermission.CREATE, ...StaffStatusPermission.UPDATE],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'person-document-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'person_document_type',
                  permissions: {
                    only: FilePersonDocumentTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/staff',
                    pathMatch: 'full'
                  },
                  {
                    path: ':person-document-type-id',
                    loadComponent: () =>
                      import(
                        './file/pages/create-update-person-document-type-form-page/create-update-person-document-type-form-page.component'
                      ).then(m => m.CreateUpdatePersonDocumentTypeFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('person-document-type-id')],
                    resolve: {
                      crud: crudResolver('person-document-type-id'),
                      breadcrumb: breadcrumbResolver('person-document-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'person_document_type',
                      permissions: {
                        only: [
                          ...FilePersonDocumentTypePermission.CREATE,
                          ...FilePersonDocumentTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'functions',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'function',
                  permissions: {
                    only: StaffFunctionPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/staff',
                    pathMatch: 'full'
                  },
                  {
                    path: ':function-id',
                    loadComponent: () =>
                      import(
                        './staff/pages/create-update-function-form-page/create-update-function-form-page.component'
                      ).then(m => m.CreateUpdateFunctionFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('function-id')],
                    resolve: {
                      crud: crudResolver('function-id'),
                      breadcrumb: breadcrumbResolver('function-id')
                    },
                    data: {
                      breadcrumbParamValue: 'function',
                      permissions: {
                        only: [
                          ...StaffFunctionPermission.CREATE,
                          ...StaffFunctionPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'price-categories',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'price_category',
                  permissions: {
                    only: StaffPriceCategoryPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/staff',
                    pathMatch: 'full'
                  },
                  {
                    path: ':price-category-id',
                    loadComponent: () =>
                      import(
                        './staff/pages/create-update-price-category-form-page/create-update-price-category-form-page.component'
                      ).then(m => m.CreateUpdatePriceCategoryFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('price-category-id')],
                    resolve: {
                      crud: crudResolver('price-category-id'),
                      breadcrumb: breadcrumbResolver('price-category-id')
                    },
                    data: {
                      breadcrumbParamValue: 'price-category',
                      permissions: {
                        only: [
                          ...StaffPriceCategoryPermission.CREATE,
                          ...StaffPriceCategoryPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'academy',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'academy',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: [
                  ...AcademyOrganisationPermission.READ,
                  ...AcademySchoolPeriodPermission.READ,
                  ...AcademyCourseTypePermission.READ,
                  ...AcademySubjectPermission.READ,
                  ...AcademyTeacherHourlyRatePermission.READ
                ],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './academy/pages/academy-management-page/academy-management-page.component'
                  ).then(m => m.AcademyManagementPageComponent)
              },
              {
                path: 'organisations',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'organisations',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyOrganisationPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:organisation-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/organisation-detail-page/organisation-detail-page.component'
                      ).then(m => m.OrganisationDetailPageComponent),
                    canActivate: [detailGuard('organisation-id')],
                    resolve: { crud: crudResolver('organisation-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'organisation'
                    }
                  },
                  {
                    path: ':organisation-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-organisation-form-page/create-update-organisation-form-page.component'
                      ).then(m => m.CreateUpdateOrganisationFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('organisation-id')],
                    resolve: {
                      crud: crudResolver('organisation-id'),
                      breadcrumb: breadcrumbResolver('organisation-id')
                    },
                    data: {
                      breadcrumbParamValue: 'organisation',
                      permissions: {
                        only: [
                          ...AcademyOrganisationPermission.CREATE,
                          ...AcademyOrganisationPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'school-periods',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'school_periods',
                  icon: 'pi pi-fw pi-eject',
                  permissions: {
                    only: AcademySchoolPeriodPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:school-period-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/school-period-detail-page/school-period-detail-page.component'
                      ).then(m => m.SchoolPeriodDetailPageComponent),
                    canActivate: [detailGuard('school-period-id')],
                    resolve: { crud: crudResolver('school-period-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'school_period'
                    }
                  },
                  {
                    path: ':school-period-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-school-period-form-page/create-update-school-period-form-page.component'
                      ).then(m => m.CreateUpdateSchoolPeriodFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('school-period-id')],
                    resolve: {
                      crud: crudResolver('school-period-id'),
                      breadcrumb: breadcrumbResolver('school-period-id')
                    },
                    data: {
                      breadcrumbParamValue: 'school_period',
                      permissions: {
                        only: [
                          ...AcademySchoolPeriodPermission.CREATE,
                          ...AcademySchoolPeriodPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'course-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'course_types',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCourseTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:course-type-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/course-type-detail-page/course-type-detail-page.component'
                      ).then(m => m.CourseTypeDetailPageComponent),
                    canActivate: [detailGuard('course-type-id')],
                    resolve: { crud: crudResolver('course-type-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'course_type'
                    }
                  },
                  {
                    path: ':course-type-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-course-type-form-page/create-update-course-type-form-page.component'
                      ).then(m => m.CreateUpdateCourseTypeFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('course-type-id')],
                    resolve: {
                      crud: crudResolver('course-type-id'),
                      breadcrumb: breadcrumbResolver('course-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'course_type',
                      permissions: {
                        only: [
                          ...AcademyCourseTypePermission.CREATE,
                          ...AcademyCourseTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'course-names',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'course_names',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCourseNamePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:course-name-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/course-name-detail-page/course-name-detail-page.component'
                      ).then(m => m.CourseNameDetailPageComponent),
                    canActivate: [detailGuard('course-name-id')],
                    resolve: { crud: crudResolver('course-name-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'course_name'
                    }
                  },
                  {
                    path: ':course-name-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-course-name-form-page/create-update-course-name-form-page.component'
                      ).then(m => m.CreateUpdateCourseNameFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('course-name-id')],
                    resolve: {
                      crud: crudResolver('course-name-id'),
                      breadcrumb: breadcrumbResolver('course-name-id')
                    },
                    data: {
                      breadcrumbParamValue: 'course_name',
                      permissions: {
                        only: [
                          ...AcademyCourseNamePermission.CREATE,
                          ...AcademyCourseNamePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'certificate-templates',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'certificate_templates',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: ':certificate-template-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-certificate-template-form-page/create-update-certificate-template-form-page.component'
                      ).then(m => m.CreateUpdateCertificateTemplateFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('certificate-template-id')],
                    resolve: {
                      crud: crudResolver('certificate-template-id'),
                      breadcrumb: breadcrumbResolver('certificate-template-id')
                    },
                    data: {
                      breadcrumbParamValue: 'certificate_template',
                      permissions: {
                        only: [Role.LESSOR, Role.MANAGER],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'subjects',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'subjects',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademySubjectPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:subject-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/subject-detail-page/subject-detail-page.component'
                      ).then(m => m.SubjectDetailPageComponent),
                    canActivate: [detailGuard('subject-id')],
                    resolve: { crud: crudResolver('subject-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'subject'
                    }
                  },
                  {
                    path: ':subject-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-subject-form-page/create-update-subject-form-page.component'
                      ).then(m => m.CreateUpdateSubjectFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('subject-id')],
                    resolve: {
                      crud: crudResolver('subject-id'),
                      breadcrumb: breadcrumbResolver('subject-id')
                    },
                    data: {
                      breadcrumbParamValue: 'subject',
                      permissions: {
                        only: [
                          ...AcademySubjectPermission.CREATE,
                          ...AcademySubjectPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'education-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'education_type',
                  icon: 'pi pi-fw pi-cog',
                  permissions: {
                    only: StaffEducationTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: ':education-type-id',
                    loadComponent: () =>
                      import(
                        './staff/pages/create-update-education-type-form-page/create-update-education-type-form-page.component'
                      ).then(m => m.CreateUpdateEducationTypeFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('education-type-id')],
                    resolve: {
                      crud: crudResolver('education-type-id'),
                      breadcrumb: breadcrumbResolver('education-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'education_type',
                      permissions: {
                        only: [
                          ...StaffEducationTypePermission.CREATE,
                          ...StaffEducationTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'teacher-hourly-rates',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'teacher_hourly_rates',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyTeacherHourlyRatePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/administration/academy',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:teacher-hourly-rate-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/teacher-hourly-rate-detail-page/teacher-hourly-rate-detail-page.component'
                      ).then(m => m.TeacherHourlyRateDetailPageComponent),
                    canActivate: [detailGuard('teacher-hourly-rate-id')],
                    resolve: { crud: crudResolver('teacher-hourly-rate-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'teacher_hourly_rate'
                    }
                  },
                  {
                    path: ':teacher-hourly-rate-id',
                    loadComponent: () =>
                      import(
                        './academy/pages/create-update-teacher-hourly-rate-form-page/create-update-teacher-hourly-rate-form-page.component'
                      ).then(m => m.CreateUpdateTeacherHourlyRateFormPageComponent),
                    canActivate: [ngxPermissionsGuard, crudGuard('teacher-hourly-rate-id')],
                    resolve: {
                      crud: crudResolver('teacher-hourly-rate-id'),
                      breadcrumb: breadcrumbResolver('teacher-hourly-rate-id')
                    },
                    data: {
                      breadcrumbParamValue: 'teacher_hourly_rate',
                      permissions: {
                        only: [
                          ...AcademyTeacherHourlyRatePermission.CREATE,
                          ...AcademyTeacherHourlyRatePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'not-found',
        data: {
          breadcrumb: 'Not Found'
        },
        loadComponent: () =>
          import('./core/pages/page-not-found-page/page-not-found-page.component').then(
            m => m.PageNotFoundPageComponent
          )
      },
      { path: '**', redirectTo: 'not-found' }
    ]
  }
];
