import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  importProvidersFrom,
  inject,
  isDevMode,
  LOCALE_ID,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core';
import localeDeAt from '@angular/common/locales/de-AT';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { provideTransloco, provideTranslocoMissingHandler } from '@jsverse/transloco';
import Keycloak from 'keycloak-js';
import { registerLocaleData } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { provideServiceWorker } from '@angular/service-worker';
import {
  AuthenticationModule,
  AuthenticationService,
  AuthorizationService
} from '~ngx-shared/authentication';
import { environment as madrasaEnvironment } from '~ngx-shared/environment/madrasa';
import { apolloOptionsFactory, appInitializerFactory } from '~ngx-shared/factories';
import { CustomMissingHandler, NavigationService, TranslocoHttpLoader } from '~ngx-shared/services';
import { FormlyModule } from '~ngx-shared/formly';
import { GlobalErrorHandler } from '~ngx-shared/errorHandlers';
import { routes } from './app.routes';
import { providePrimeNG } from 'primeng/config';
import { CalendarModule } from '~ngx-shared/calendar';

registerLocaleData(localeDeAt);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'de-AT' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideAppInitializer(() => appInitializerFactory(inject(NavigationService))()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: { options: { darkModeSelector: '.app-dark' } },
      ripple: true
    }),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      AuthenticationModule.forRoot({
        clientId: madrasaEnvironment.clientId
      }),
      CalendarModule.forRoot(),
      FormlyModule.forRoot()
    ),
    FormlyModule.forRootProvider(),
    provideApollo(() =>
      apolloOptionsFactory(
        inject(HttpLink),
        inject(AuthenticationService),
        inject(AuthorizationService),
        inject(Keycloak)
      )
    ),
    provideTransloco({
      config: {
        availableLangs: ['de-AT'],
        defaultLang: 'de-AT',
        prodMode: !isDevMode()
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoMissingHandler(CustomMissingHandler),
    MessageService,
    ConfirmationService,
    DialogService,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
