import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '~ngx-shared/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ClaimInterceptor implements HttpInterceptor {
  private authenticationService = inject(AuthenticationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isHasuraOrExternal =
      request.url.startsWith(environment.hasuraUrl) ||
      request.url.startsWith(environment.externalServiceUrl);

    if (!isHasuraOrExternal) {
      return next.handle(request);
    }

    let headers = request.headers;
    const storedHeaders = this.authenticationService.authStore.value?.['headers'];
    if (storedHeaders) {
      headers = Object.keys(storedHeaders).reduce(
        (acc, key) => acc.set(key, storedHeaders[key]),
        headers
      );
    }

    return next.handle(
      request.clone({
        headers: headers
      })
    );
  }
}
