import { Component, effect, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, delay, Observable, of, switchMap } from 'rxjs';
import { LayoutConfig, LayoutService, LoadingService, MenuService } from '~ngx-shared/layout';
import { AuthenticationService, AuthorizationService } from '~ngx-shared/authentication';
import { VersionService } from '~ngx-shared/services';
import { TenantService, UserService } from '~madrasa/services';
import {
  AcademyClassBookEntryPermission,
  AcademyCoursePermission,
  AcademyRoomPermission,
  AcademySchoolPermission,
  AcademyUnitPermission,
  AccountingCashBookEntryPermission,
  AccountingMembershipFeePermission,
  AccountingStudentBalancePermission,
  CorePersonPermission,
  CoreTenantModel,
  CoreTenantPermission,
  StaffDirectorPermission,
  StaffManagerPermission,
  StaffMemberPermission,
  StaffOrganisatorPermission,
  StaffParentPermission,
  StaffStudentPermission,
  StaffTeacherPermission
} from './models';
import { Role } from '~madrasa/core/enums';
import { PrimeNG } from 'primeng/config';
import { Madrasa } from '~madrasa/theme/madrasa';
import { KEYCLOAK_EVENT_SIGNAL, KeycloakEventType } from 'keycloak-angular';

@UntilDestroy()
@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private layoutService = inject(LayoutService);
  private translocoService = inject(TranslocoService);
  private loadingService = inject(LoadingService);
  private menuService = inject(MenuService);
  private versionService = inject(VersionService);
  private messageService = inject(MessageService);
  private tenantService = inject(TenantService);
  private primeNgConfig = inject(PrimeNG);
  private keycloakSignal = inject(KEYCLOAK_EVENT_SIGNAL);

  constructor() {
    const authorizationService = inject(AuthorizationService);
    const authenticationService = inject(AuthenticationService);
    const userService = inject(UserService);

    effect(() => {
      const isNewAvailable = this.versionService.isNewVersionAvailable();
      if (isNewAvailable) {
        this.messageService.add({
          severity: 'error',
          summary: 'update',
          closable: false,
          sticky: true,
          key: 'new_version_available'
        });
      }
    });

    effect(() => {
      const keycloakEvent = this.keycloakSignal();

      if (keycloakEvent.type === KeycloakEventType.Ready) {
        authenticationService.init();
        authorizationService.setAllowedRoles(Object.values(Role));
        userService.init();
      }
    });
  }

  ngOnInit(): void {
    this.loadingService.startLoading();

    //optional configuration with the default configuration
    const config: LayoutConfig = {
      preset: 'Aura',
      primary: 'emerald',
      surface: null,
      darkTheme: false,
      menuMode: 'static'
    };
    this.layoutService.layoutConfig.set(config);
    this.primeNgConfig.theme.set({
      ...this.primeNgConfig.theme(),
      preset: Madrasa
    });

    this.translocoService.selectTranslateObject('primeng').subscribe(translated => {
      this.primeNgConfig.setTranslation(translated);
    });

    this.menuService.sideBarItems$.next([
      {
        label: 'Home',
        items: [
          {
            label: 'dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/', 'academy', 'dashboard']
          },
          {
            label: 'profile',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/', 'staff', 'profile']
          }
        ]
      },
      {
        label: 'staff',
        state: {
          permissions: [
            ...StaffStudentPermission.READ,
            ...StaffParentPermission.READ,
            ...StaffTeacherPermission.READ,
            ...StaffDirectorPermission.READ,
            ...StaffOrganisatorPermission.READ,
            ...StaffManagerPermission.READ,
            ...StaffMemberPermission.READ,
            ...CorePersonPermission.READ
          ]
        },
        items: [
          {
            label: 'students',
            icon: 'pi pi-fw pi-graduation-cap',
            routerLink: ['/', 'staff', 'students'],
            state: { permissions: StaffStudentPermission.READ }
          },
          {
            label: 'parents',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'parents'],
            state: { permissions: StaffParentPermission.READ }
          },
          {
            label: 'teachers',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/', 'staff', 'teachers'],
            state: { permissions: StaffTeacherPermission.READ }
          },
          {
            label: 'directors',
            icon: 'pi pi-fw pi-building-columns',
            routerLink: ['/', 'staff', 'directors'],
            state: { permissions: StaffDirectorPermission.READ }
          },
          {
            label: 'organisators',
            icon: 'pi pi-fw pi-briefcase',
            routerLink: ['/', 'staff', 'organisators'],
            state: { permissions: StaffOrganisatorPermission.READ }
          },
          {
            label: 'managers',
            icon: 'pi pi-fw pi-briefcase',
            routerLink: ['/', 'staff', 'managers'],
            state: { permissions: StaffManagerPermission.READ }
          },
          {
            label: 'members',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'members'],
            state: { permissions: StaffMemberPermission.READ }
          },
          {
            label: 'people',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'people'],
            state: { permissions: CorePersonPermission.READ }
          }
        ]
      },
      {
        label: 'academy',
        items: [
          {
            label: 'class_book_entries',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/', 'academy', 'class-book-entries'],
            state: { permissions: AcademyClassBookEntryPermission.READ }
          },
          {
            label: 'units',
            icon: 'pi pi-fw pi-calendar-clock',
            routerLink: ['/', 'academy', 'units'],
            state: { permissions: AcademyUnitPermission.READ }
          },
          {
            label: 'courses',
            icon: 'pi pi-fw pi-graduation-cap',
            routerLink: ['/', 'academy', 'courses'],
            state: { permissions: AcademyCoursePermission.READ },
            items: [
              {
                label: 'evaluations',
                icon: 'pi pi-fw pi-graduation-cap',
                routerLink: ['/', 'academy', 'courses', 'evaluations'],
                state: { permissions: AcademyCoursePermission.CREATE }
              }
            ]
          },
          {
            label: 'rooms',
            icon: 'pi pi-fw pi-building',
            routerLink: ['/', 'academy', 'rooms'],
            state: { permissions: AcademyRoomPermission.READ }
          },
          {
            label: 'schools',
            icon: 'pi pi-fw pi-building-columns',
            routerLink: ['/', 'academy', 'schools'],
            state: { permissions: AcademySchoolPermission.READ }
          },
          {
            label: 'documents',
            icon: 'pi pi-fw pi-file',
            routerLink: ['/', 'academy', 'documents']
          },
          {
            label: 'statistics',
            icon: 'pi pi-fw pi-chart-scatter',
            routerLink: ['/', 'academy', 'statistics'],
            state: { permissions: [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR] }
          }
        ]
      },
      {
        label: 'accounting',
        items: [
          {
            label: 'account_plan',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'account-plan'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'account_entries',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'entries'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'cash_book_entries',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'cash-book-entries'],
            state: { permissions: AccountingCashBookEntryPermission.READ }
          },
          {
            label: 'student_balances',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'student-balances'],
            state: { permissions: AccountingStudentBalancePermission.READ }
          },
          {
            label: 'membership_fees',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'membership-fees'],
            state: { permissions: AccountingMembershipFeePermission.READ }
          },
          {
            label: 'teacher_wages',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'teacher-wages'],
            state: { permissions: AccountingStudentBalancePermission.READ }
          },
          {
            label: 'settlements',
            icon: 'pi pi-fw pi-dollar',
            routerLink: ['/', 'accounting', 'settlements'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'earnings_report',
            icon: 'pi pi-fw pi-chart-scatter',
            routerLink: ['/', 'accounting', 'earnings-report'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'bank_data_import',
            icon: 'pi pi-fw pi-file-import',
            routerLink: ['/', 'accounting', 'bank-data-import'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          }
        ],
        state: {
          permissions: [
            Role.LESSOR,
            Role.MANAGER,
            ...AccountingCashBookEntryPermission.READ,
            ...AccountingStudentBalancePermission.READ
          ]
        }
      },
      {
        label: 'administration',
        items: [
          {
            label: 'staff',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/', 'administration', 'staff']
          },
          {
            label: 'academy',
            icon: 'pi pi-fw pi-building-columns',
            routerLink: ['/', 'administration', 'academy']
          },
          {
            label: 'accounting',
            icon: 'pi pi-fw pi-calculator',
            routerLink: ['/', 'administration', 'accounting']
          },
          {
            label: 'print',
            icon: 'pi pi-fw pi-print',
            routerLink: ['/', 'administration', 'print']
          }
        ],
        state: { permissions: [Role.LESSOR, Role.MANAGER] }
      },
      {
        label: 'System',
        items: [
          {
            label: 'tenants',
            icon: 'pi pi-fw pi-building-columns',
            routerLink: ['/', 'core', 'tenants']
          }
        ],
        state: { permissions: CoreTenantPermission.READ }
      }
    ]);
    this.fetchTenantsUntilResult().subscribe(tenants => this.loadingService.stopLoading());
  }

  fetchTenantsUntilResult(): Observable<CoreTenantModel[] | undefined> {
    return this.tenantService.getTenants(false).pipe(
      untilDestroyed(this),
      switchMap(tenants => {
        if (tenants && tenants.length > 0) {
          return of(tenants);
        } else {
          return of(null).pipe(
            untilDestroyed(this),
            delay(2000),
            switchMap(() => this.fetchTenantsUntilResult())
          );
        }
      }),
      catchError(error => {
        console.error('Error fetching tenants:', error);
        return of(null).pipe(
          untilDestroyed(this),
          delay(2000),
          switchMap(() => this.fetchTenantsUntilResult())
        );
      })
    );
  }
}
