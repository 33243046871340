import { ErrorHandler, inject, Injectable } from '@angular/core';
import { LoggerService } from '~ngx-shared/services';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  private loggerService = inject(LoggerService);

  handleError(error: any): void {
    console.error('An error occurred:', error);
    this.loggerService.error(JSON.stringify(error), error?.stack);
  }
}
